import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Detect } from "./Detect";

const coreProps = {
  iosUrl: process.env.REACT_APP_IOS_URL, 
  androidUrl: process.env.REACT_APP_ANDROID_URL};

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Detect { ...coreProps } />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
