import React from 'react';
import UAParser from 'ua-parser-js'
import './App.css'

export function Detect (props) {
      const { iosUrl, androidUrl } = props;
      const uaParser = new UAParser()

      uaParser.setUA(window.navigator.userAgent)
      if (uaParser.getOS().name === 'iOS')
        window.location=iosUrl
  
      if (uaParser.getOS().name === 'Android')
        window.location=androidUrl
  
      return (
        <div className="App">
            <h2>This link is intended to run from a mobile device</h2>
        </div>
      );
  }